import React, { useEffect, useState } from "react";
import { ValidateInputFields, isNegative } from "../../validations/ValidateInputFields";
import Button from "../../widgets/Button";
import { handleToast } from "../../utils/handleToast";
import {
  useAddRiskInfoMutation,
  useGetRiskInfoQuery,
  useUpdateRiskInfoMutation,
} from "../../../redux/riskInfoApi";
import TableLoader from "../../widgets/TableLoader";
import { useTranslation } from "react-i18next";

const defaultState = {
  maxSharesPerDay: { value: "", required: false, label: "Max Coins Per Day" },
  maxTargetProfit: { value: "", required: false, label: "Max Target Profit" },
  maxSharesPerMonth: { value: "", required: false, label: "Max Coins Per Month" },
  maxLossPerMonth: { value: "", required: false, label: "Max Loss Per Month" },
  maxSharesPerPosition: { value: "", required: false, label: "Max Coins Per Position" },
  maxOpenShares: { value: "", required: false, label: "Max Open Coins" },
  maxBpPerStock: { value: "", required: false, label: "Max Bp Per Coin" },
  stockPriceRestriction: { value: "", required: false, label: "Coin Price Restriction" },
  dailyAutoClose: { value: "", required: false, label: "Daily Auto Close" },
  balanceAutoClose: { value: "", required: false, label: "Coin Balance Auto Close" },
  noNews: { value: "N", required: true, label: "No News" },
  allowWeekendTrading: { value: "Y", required: true, label: "Allow Weekend Trading" },
  newsDisableStime: { value: "", required: false, label: "News Disable Start Time" },
  newsDisableEtime: { value: "", required: false, label: "News Disable End Time" },
};

const defaultErrorState = {
  maxSharesPerDay: "",
  maxTargetProfit: "",
  maxSharesPerMonth: "",
  maxLossPerMonth: "",
  maxSharesPerPosition: "",
  maxOpenShares: "",
  maxBpPerStock: "",
  stockPriceRestriction: "",
  dailyAutoClose: "",
  balanceAutoClose: "",
  noNews: "",
  allowWeekendTrading: "",
  newsDisableStime: "",
  newsDisableEtime: "",
};

const CryptoRiskInfo = ({ targetUserId, refetch }) => {
  const { t } = useTranslation();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get the user's time zone
  const [cryptoRiskInfo, setCryptoRiskInfo] = useState(defaultState);
  const [cryptoRiskInfoError, setCryptoRiskInfoError] = useState(defaultErrorState);
  const [addRiskInfo, { isLoading: addingRiskInfo }] = useAddRiskInfoMutation();
  const [updateRiskInfo, { isLoading: updatingRiskInfo }] = useUpdateRiskInfoMutation();
  const {
    data,
    isLoading,
    refetch: refetchRiskInfo,
  } = useGetRiskInfoQuery({
    targetUserId,
    riskInfoType: "crypto",
    userTimeZone: userTimeZone,
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const updatedStockRiskInfo = Object.keys(defaultState).reduce((acc, key) => {
        let value = data[0][key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()];

        value = value === null || value === "null" ? "" : value;
        if (key === "newsDisableStime" || key === "newsDisableEtime") {
          value = value || "";
        }

        acc[key] = {
          ...defaultState[key],
          value: value,
        };
        return acc;
      }, {});
      setCryptoRiskInfo(updatedStockRiskInfo);
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCryptoRiskInfo((prev) => {
      return { ...prev, [name]: { ...prev[name], value } };
    });
    let validationError = ValidateInputFields(name, value, cryptoRiskInfo[name].required);
    if (validationError) {
      setCryptoRiskInfoError((prevError) => ({
        ...prevError,
        [name]: validationError,
      }));
      return;
    }

    validationError = isNegative(value);
    setCryptoRiskInfoError((prevError) => ({
      ...prevError,
      [name]: validationError,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate if newsDisableEtime is greater than newsDisableStime
    const newsDisableStime = cryptoRiskInfo.newsDisableStime.value;
    const newsDisableEtime = cryptoRiskInfo.newsDisableEtime.value;

    if (newsDisableStime && newsDisableEtime && new Date(newsDisableEtime) <= new Date(newsDisableStime)) {
      setCryptoRiskInfoError((prevError) => ({
        ...prevError,
        newsDisableEtime: t("End time must be greater than start time."),
      }));
      return; // Prevent form submission if the validation fails
    }

    const handleUpdateOrAdd = async () => {
      const values = Object.fromEntries(
        Object.entries(cryptoRiskInfo).map(([key, { value }]) => [key, value])
      );

      for (let key in cryptoRiskInfo) {
        let validationError = ValidateInputFields(key, cryptoRiskInfo[key].value, cryptoRiskInfo[key].required);
        setCryptoRiskInfoError((prevError) => ({
          ...prevError,
          [key]: validationError,
        }));
        if (validationError) return;

        validationError = isNegative(cryptoRiskInfo[key].value);
        if (validationError) return;
      }

      const body = { 
        userTimeZone: userTimeZone,
        targetUserId, 
        riskInfoType: "crypto", 
        ...values 
      };

      const mutationResult = data && data.length > 0
        ? await updateRiskInfo(body)
        : await addRiskInfo(body);
      handleToast(t, mutationResult);
      if (mutationResult?.data?.status) {
        refetch();
        refetchRiskInfo();
      }
    };

    handleUpdateOrAdd();
  };

  if (isLoading) {
    return <TableLoader />;
  }

  return (
    <form>
      <div className="row">
        {Object.keys(defaultState).map((key) => {
          if ((key === "newsDisableStime" || key === "newsDisableEtime") && cryptoRiskInfo.noNews.value !== "Y") {
            return null;
          }

          if (key === "noNews" || key === "allowWeekendTrading") {
            return (
              <div key={key} className="form-group col-md-6">
                <label className="fw-bold">
                  {t(cryptoRiskInfo[key].label)}
                </label>
                <select
                  className="form-control"
                  name={key}
                  onChange={handleChange}
                  value={cryptoRiskInfo[key].value}
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
                <span style={{ color: "red" }}>
                  {t(cryptoRiskInfoError[key])}
                </span>
              </div>
            );
          }

          return (
            <div key={key} className="form-group col-md-6">
              <label className="fw-bold">
                {t(cryptoRiskInfo[key].label)}
                {cryptoRiskInfo[key].required ? "*" : ""}
              </label>
              <input
                type={
                  key === "newsDisableStime" || key === "newsDisableEtime"
                    ? "datetime-local"
                    : "text"
                }
                className="form-control"
                placeholder={t(`Enter ${cryptoRiskInfo[key].label}`)}
                name={key}
                onChange={handleChange}
                value={cryptoRiskInfo[key].value}
              />
              <span style={{ color: "red" }}>
                {t(cryptoRiskInfoError[key])}
              </span>
            </div>
          );
        })}
      </div>
      <Button
        className="btn btn1"
        variant={"primary"}
        isLoading={addingRiskInfo || updatingRiskInfo}
        onClick={handleSubmit}
      >
        {data && data.length > 0 ? t("Update") : t("Submit")}
      </Button>
    </form>
  );
};

export default CryptoRiskInfo;
