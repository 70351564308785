import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useGetCryptoPairsQuery } from "../../../redux/cryptoPairsApi";
import TableLoader from "../../widgets/TableLoader";
import toast from "react-hot-toast";
import {
  useGetLeveragesPermissionsQuery,
  useLeveragePermissionMutation,
} from "../../../redux/leverageApi";
import Button from "../../widgets/Button";
import { useTranslation } from "react-i18next";

export const Leverage = ({ editData }) => {
  const { t } = useTranslation();
  const [selectedPairs, setSelectedPairs] = useState({
    global: null,
    crypto: null,
    forex: null,
    equity: null,
  });
  const [selectedOptions, setSelectedOptions] = useState({
    global: null,
    crypto: null,
    forex: null,
    equity: null,
  });
  const [isShow, setIsShow] = useState(false);
  const [currantPairs, setCurrantPairs] = useState([]);
  const [errors, setErrors] = useState({ global: false });

  const {
    data: pairsData,
    isLoading: pairsLoading,
    refetch: refetchPairs,
  } = useGetLeveragesPermissionsQuery({ userId: editData.id });
  const { data: records, isLoading } = useGetCryptoPairsQuery();
  const [updateLeveragePermissions, { isLoading: updatingAssets }] =
    useLeveragePermissionMutation();

  useEffect(() => {
    const createKeyValuePair = (leverageAmounts) => {
      return leverageAmounts?.map((lev) => ({
        value: lev?.id,
        label: `${lev.amount} (${lev.type})`,
      }));
    };

    if (pairsData && editData) {
      const list = [...pairsData.parentPermissions];
      const pairValues = createKeyValuePair(list);
      setCurrantPairs(pairValues);

      if (pairsData.ownPermissions && pairsData.ownPermissions.length > 0) {
        const {
          global_leverage_id,
          forex_leverage_id,
          equity_leverage_id,
          crypto_leverage_id,
        } = pairsData.ownPermissions[0];

        const findOption = (id) =>
          pairValues.find((option) => option.value === id);

        setSelectedOptions({
          crypto: findOption(crypto_leverage_id),
          forex: findOption(forex_leverage_id),
          equity: findOption(equity_leverage_id),
          global: findOption(global_leverage_id),
        });

        setSelectedPairs({
          crypto: findOption(crypto_leverage_id),
          forex: findOption(forex_leverage_id),
          equity: findOption(equity_leverage_id),
          global: findOption(global_leverage_id),
        });
      } else {
        setSelectedOptions({
          crypto: null,
          forex: null,
          equity: null,
          global: null,
        });
        setSelectedPairs({
          crypto: null,
          forex: null,
          equity: null,
          global: null,
        });
      }

      setIsShow(true);
    }
  }, [editData, pairsData]);

  const validate = () => {
    const isGlobalValid = selectedPairs.global !== null;
    setErrors({ global: !isGlobalValid });
    return isGlobalValid;
  };

  const handlePermissionsTabsSubmit = async () => {
    if (!validate()) {
      toast.error(t("Global Leverage is required"));
      return;
    }

    console.log(
      {
        leverageIds: {
          crypto: selectedPairs.crypto?.value || null,
          forex: selectedPairs.forex?.value || null,
          equity: selectedPairs.equity?.value || null,
          global: selectedPairs.global?.value || null,
        },
        userId: editData.id,
      },
      "6668989898"
    );
    // return;

    const result = await updateLeveragePermissions({
      leverageIds: {
        crypto: selectedPairs.crypto?.value || null,
        forex: selectedPairs.forex?.value || null,
        equity: selectedPairs.equity?.value || null,
        global: selectedPairs.global?.value || null,
      },
      userId: editData.id,
    });
    toast.dismiss();
    if (result?.data?.status) {
      toast.success(t(result.data.message));
      refetchPairs();
    } else {
      toast.error(t(result?.data?.message) || t("Error occurred"));
    }
  };

  const handleChange = (selected, key) => {
    setSelectedPairs((prev) => ({
      ...prev,
      [key]: selected,
    }));

    if (key === "global") {
      setErrors((prev) => ({
        ...prev,
        global: selected === null,
      }));
    }
  };

  if (!pairsData && !records) {
    return <TableLoader />;
  }

  return (
    <>
      {isLoading && pairsLoading ? (
        <TableLoader />
      ) : (
        <div>
          <h4>{t("Select Leverage")}</h4>
          {!isShow ? (
            <TableLoader />
          ) : (
            <>
              <div className="row">
                {Object.keys(selectedPairs).map((key) => (
                  <div className="mt-2 col-6" key={key}>
                    <h5>
                      {t(
                        `${key.charAt(0).toUpperCase() + key.slice(1)} Leverage`
                      )}
                    </h5>
                    <Select
                      defaultValue={selectedOptions[key]}
                      name={key}
                      options={currantPairs}
                      className={`basic-multi-select ${
                        key === "global" && errors.global ? "error" : ""
                      }`}
                      classNamePrefix="select"
                      isLoading={isLoading}
                      onChange={(selected) => handleChange(selected, key)}
                    />
                    {key === "global" && errors.global && (
                      <span className="text-danger">
                        {t("Global Leverage is required")}
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <Button
                className="btn btn1 mt-2"
                onClick={handlePermissionsTabsSubmit}
                isLoading={updatingAssets}
              >
                {t("Save")}
              </Button>
            </>
          )}
        </div>
      )}
    </>
  );
};
