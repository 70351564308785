import { apiSlice } from "./api";
import {
  getRiskInfoApi,
  postRiskInfoApi,
  updateRiskInfoApi,
} from "../components/constant/Api";

export const riskInfoApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getCommission: builder.query({
    //   query: () => ({
    //     url: getCommissionsApi,
    //     method: "GET",
    //   }),
    //   transformResponse: (response, meta, arg) => {
    //     return response.status ? response?.data ?? [] : [];
    //   },
    // }),
    // addCommission: builder.mutation({
    //   query: (post) => ({
    //     url: postCommissionsApi,
    //     method: "POST",
    //     body: post,
    //   }),

    //   invalidatesTags: (_) => ["commission"],
    // }),
    // updateCommission: builder.mutation({
    //   query: (post) => ({
    //     url: updateCommissionsApi,
    //     method: "POST",
    //     body: post,
    //   }),

    //   invalidatesTags: (_) => ["commission"],
    // }),
    // deleteCommission: builder.mutation({
    //   query: (post) => ({
    //     url: deleteCommissionsApi,
    //     method: "POST",
    //     body: post,
    //   }),

    //   invalidatesTags: (_) => ["commission"],
    // }),
    getRiskInfo: builder.query({
      query: ({ targetUserId, riskInfoType, userTimeZone}) => ({
        url:  `${getRiskInfoApi}/${targetUserId}/${riskInfoType}?userTimeZone=${userTimeZone}`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => {
        return response.status ? response?.data ?? [] : [];
      },
      invalidatesTags: (_) => ["riskInfo"],
    }),
    addRiskInfo: builder.mutation({
      query: (post) => ({
        url: postRiskInfoApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["riskInfo"],
    }),
    updateRiskInfo: builder.mutation({
      query: (post) => ({
        url: updateRiskInfoApi,
        method: "POST",
        body: post,
      }),

      invalidatesTags: (_) => ["riskInfo"],
    }),
  }),
});

export const {
  useAddRiskInfoMutation,
  useGetRiskInfoQuery,
  useUpdateRiskInfoMutation,
} = riskInfoApi;
